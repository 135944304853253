import React, { useState } from "react";
import "twin.macro";
import tw, { styled } from "twin.macro";

import { useNavigate, useParams } from "react-router-dom";

import { useShare } from "../../clients/apiHooks";

import { BackButton, NeuButton } from "../generic/Neu";
import { ErrorBoundary } from "@sentry/react";

import { MenuCol, MarginWrapper } from "../generic/Layout";
import { EditableTitle } from "../album/EditableTitle";
import { InviteEmailInput } from "./SharedWith";
import { ItemGroupContainer, LabelItem } from "../tags/Items";
import { ShareQueries } from "./ShareQueries";
import { ShareContents } from "./ShareContents";
import { EditQuery } from "./AddNewQuery";
import ConfirmDialog from "./confirmDialog";
import { CollectionShareList } from "../navigation/LandingPage";
import { PatchShareParameters, ShareDetails } from "../../clients/types";
import { AppTitle } from "../Title";

const ShareCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all duration-200 ease-out relative`,
  props.active
    ? tw`w-full sm:w-[80%]`
    : tw`hidden sm:block min-w-[250px] w-[20%]`,
]);

const QueryCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all duration-200 ease-out relative pr-4 top-16 p-4 z-10 bg-bgbase`,
  props.active ? tw`w-full sm:w-1/3` : tw`w-0`,
]);

function ShareView() {
  const { shareId, collectionId, albumId } = useParams<{
    shareId: string;
    collectionId: string;
    albumId: string;
  }>();
  const isCondensed = !!shareId && !!collectionId && !!albumId;

  return (
    <div tw="max-w-screen-fhd flex flex-col sm:flex-row mx-auto gap-x-20">
      <MenuCol active={!isCondensed}>
        <div tw="fixed top-0 h-screen overflow-y-auto">
          <CollectionShareList />
        </div>
      </MenuCol>
      <ErrorBoundary
        fallback={<div>Something went wrong loading the share...</div>}
      >
        <ShareViewInner shareId={shareId} />
      </ErrorBoundary>
    </div>
  );
}

export const createUpdateTitle = (
  share: ShareDetails | undefined,
  patchShare: (payload: PatchShareParameters) => Promise<void>,
) => {
  return share?.isOwner
    ? async (newName: string) => {
        if (!share) {
          return;
        }
        await patchShare({ name: newName, shareId: share.id });
      }
    : undefined;
};

function ShareViewInner({ shareId }: { shareId?: string }) {
  const [editingQueryId, setEditingQueryId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { share, patchShare, deleteShare, unInviteFromShare } =
    useShare(shareId);
  const navigate = useNavigate();

  const updateName = createUpdateTitle(share, patchShare.mutateAsync);

  async function handleDeleteShare() {
    if (!shareId || !share?.isOwner) {
      return;
    }

    try {
      await deleteShare.mutateAsync(shareId);
      setIsDeleteDialogOpen(false);
      navigate("/collection");
    } catch (error) {
      console.error("Fail to delete share", error);
    }
  }

  // TODO: Add nicer spinner/skeleton
  if (!share) return <div>Loading...</div>;

  return (
    <>
      <AppTitle contentTitle={share.name} />
      <ShareCol active={!!shareId}>
        <MarginWrapper isCenter={!!shareId} hasMenu>
          <div tw="flex flex-row justify-between">
            <BackButton tw="mb-8 sm:hidden" />
            {share.isOwner && (
              <div tw="text-sm mr-24">
                <NeuButton
                  tw="bg-danger"
                  icon="mi-delete"
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                >
                  Delete share group
                </NeuButton>
              </div>
            )}
          </div>
          <div tw="sm:space-y-12">
            <div id="headerDiv" tw="flex flex-col gap-6 justify-between">
              <div tw="w-full mt-4">
                <span tw="label">Share group</span>
                <div tw="text-3xl sm:text-7xl w-full">
                  <EditableTitle
                    tw="sm:mb-4"
                    onUpdate={updateName}
                    title={share.name}
                  />
                </div>
              </div>
              {share.isOwner && (
                <div tw="space-y-2 mt-10">
                  <span tw="label text-grey-dark mb-4">Group members</span>
                  {share.sharees.length === 0 && (
                    <span tw="text-2xl">
                      <div>There are no members in this share group.</div>
                      <div>You can invite people using their email.</div>
                    </span>
                  )}
                  <ItemGroupContainer tw="-ml-1">
                    {share.sharees.map((sharee) => {
                      return (
                        <LabelItem
                          key={sharee}
                          onDelete={() => {
                            unInviteFromShare.mutate({
                              shareId: share.id,
                              email: sharee,
                            });
                          }}
                        >
                          {sharee}
                        </LabelItem>
                      );
                    })}
                    <InviteEmailInput shareId={shareId} />
                  </ItemGroupContainer>
                </div>
              )}
            </div>
            {share.isOwner && (
              <div tw="mt-10">
                <ShareQueries
                  share={share}
                  onAddNewQuery={
                    editingQueryId === null
                      ? () => setEditingQueryId("new")
                      : null
                  }
                  onEditQuery={
                    editingQueryId === null
                      ? (id) => setEditingQueryId(id)
                      : null
                  }
                />
              </div>
            )}
          </div>
          <ConfirmDialog
            isOpen={isDeleteDialogOpen}
            onAccept={() => handleDeleteShare()}
            onDecline={() => setIsDeleteDialogOpen(false)}
            body={
              <>
                Are you sure you want to delete this share?
                <br /> This action cannot be undone.
              </>
            }
            confirmText={"Delete share"}
            declineText={"Cancel"}
            onClose={() => setIsDeleteDialogOpen(false)}
          />
          <div tw="grid gap-4">
            <ShareContents share={share} />
          </div>
        </MarginWrapper>
      </ShareCol>
      <QueryCol active={editingQueryId != null}>
        {editingQueryId && shareId && (
          <EditQuery
            key={editingQueryId}
            shareId={shareId}
            queryId={editingQueryId}
            onClose={() => setEditingQueryId(null)}
            share={share}
          />
        )}
      </QueryCol>
    </>
  );
}

export default ShareView;
