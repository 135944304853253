import tw, { styled } from "twin.macro";
import { NeuInput } from "./Neu";
import { useCallback, useEffect, useRef, useState } from "react";

export type SuggestionInputProps = {
  value: string;
  onChange: (val: string) => void;
  onSuggestionsRequested: (needed: boolean) => void;
  suggestions: string[];
  className?: string;
  placeholder?: string;
};

const SuggestionLine = styled.li(({ active }: { active?: boolean }) => [
  tw`cursor-pointer p-2`,
  tw`hover:(bg-bgbase-dark text-[#0f0f0f])`,
  active ? tw`bg-bgbase-dark` : null,
]);

export function SuggestionInput({
  value,
  onChange,
  suggestions,
  onSuggestionsRequested,
  ...rest
}: SuggestionInputProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => onSuggestionsRequested(isFocused),
    [isFocused, onSuggestionsRequested],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (suggestions.length === 0) {
        return;
      }

      if (event.key === "ArrowDown") {
        if (selectedIndex === null || selectedIndex < suggestions.length - 1) {
          setSelectedIndex((prevIndex) =>
            prevIndex !== null ? prevIndex + 1 : 0,
          );
        }
        return;
      }

      if (event.key === "ArrowUp") {
        if (selectedIndex === null || selectedIndex > 0) {
          setSelectedIndex((prevIndex) =>
            prevIndex !== null ? prevIndex - 1 : suggestions.length - 1,
          );
        }
        return;
      }

      if (event.key === "Enter" && selectedIndex !== null) {
        inputRef.current?.blur();
        onSuggestionsRequested(false);
        onChange(suggestions[selectedIndex]);
        setSelectedIndex(null);
        return;
      }
    },
    [suggestions, selectedIndex, onChange, onSuggestionsRequested],
  );

  return (
    <div
      onFocus={() => setIsFocused(true)}
      onBlur={(ev) => {
        // if the user clicks on one of the suggestions, we'll get a blur and a focus, so ignore that
        if (!divRef.current?.contains(ev.relatedTarget) || !ev.relatedTarget) {
          setIsFocused(false);
        }
      }}
      ref={divRef}
    >
      <NeuInput
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        {...rest}
      />
      {suggestions.length > 0 && isFocused && (
        <ul tw="absolute left-0 right-0 border rounded z-10 overflow-auto shadow-haptic-small bg-grey-lightest">
          {suggestions.map((item, index) => {
            const isSelected = selectedIndex === index;

            return (
              <SuggestionLine
                onClick={(ev) => {
                  ev.currentTarget.blur();
                  onSuggestionsRequested(false);
                  onChange(item);
                }}
                key={item}
                tabIndex={0}
                active={isSelected}
              >
                {item}
              </SuggestionLine>
            );
          })}
        </ul>
      )}
    </div>
  );
}
