export function isProduction() {
  return window.location.hostname === "exrepo.com";
}

export function getEnvironment() {
  if (isProduction()) {
    return "prod";
  } else if (window.location.hostname === "staging.exrepo.com") {
    return "staging";
  } else {
    const match = window.location.hostname.match(/([a-z]+).exrepo.com/);
    if (match) {
      return "preview/" + match[1];
    } else {
      return "unknown";
    }
  }
}

export function isLocalhost() {
  return window.location.hostname.startsWith("localhost");
}
