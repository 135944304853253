import React from "react";
import tw, { styled } from "twin.macro";

// TODO: create a full layout component

interface MarginWrapperProps {
  isCenter?: boolean;
  hasMenu?: boolean;
  children?: React.ReactNode;
  isScroll?: boolean;
}

export const MarginWrapper = styled.div((props: MarginWrapperProps) => [
  tw`absolute w-full transition-all duration-200 ease-out h-screen overflow-x-visible max-w-screen-xl pr-5 pl-3 pb-8 pt-4`,
  props.isCenter ? tw`left-1/2 -translate-x-1/2` : tw`left-0 translate-x-0`,
  props.isScroll && tw`overflow-hidden`,
]);

export const MenuCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all duration-200 ease-out overflow-y-auto h-screen`,
  props.active
    ? tw`hidden sm:block min-w-[250px] w-[20%] opacity-100`
    : tw`w-0 min-w-0 opacity-0`,
]);

export const MainCol = styled.div(
  (props: { active?: boolean; isScroll?: boolean }) => [
    tw`transition-all duration-200 ease-out relative`,
    props.active
      ? tw`w-full sm:w-[80%]`
      : tw`hidden sm:block min-w-[250px] w-[20%]`,
    props.isScroll && tw`overflow-hidden`,
  ],
);
export const AlbumCol = styled.div((props: { active?: boolean }) => [
  tw`transition-none sm:transition-all duration-200 sm:ease-out relative`,
  props.active ? tw`w-full sm:w-[80%]` : tw`w-0`,
]);
