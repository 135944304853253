import "twin.macro";
import { useState } from "react";

import { useEffect, memo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useAsset, useAssets } from "../../clients/apiHooks";

import { ErrorBoundary } from "@sentry/react";
import { DocumentView } from "./DocumentView";
import { BackButton, NeuButton } from "../generic/Neu";

import { TagItem, ItemGroupContainer } from "../tags/Items";
import { EditableTitle } from "./EditableTitle";
import AssetSharedInList from "./AssetSharedInList";
import ConfirmDialog from "../share/confirmDialog";
import { AppTitle } from "../Title";
import { getPage } from "../generic/Pagination";
import { albumViewPageSize } from "../../clients/apiClient";
import { Asset, SortOrder } from "../../clients/types";

export interface AssetDetailsProps {
  id: string;
  albumId: string;
  collectionId: string;
  shareId?: string;
  className?: string;
  onSelect: () => void;
  onClose: () => void;
  showDeleteDialogOnNextDelete?: boolean;
  setShowDeleteDialogOnNextDelete?: (
    showDeleteDialogOnNextDelete: boolean,
  ) => void;
  hideDeleteButton?: boolean;
  sortOrder?: SortOrder;
  searchQuery: string;
}

export const getAssetNavigationLinks = ({
  assetId,
  page,
  pages,
  assets,
  albumLink,
}: {
  assetId: string;
  page: number;
  pages: number;
  assets: Asset[];
  albumLink: string;
}) => {
  const currIdx = assets.findIndex((ast: Asset) => ast.id === assetId);
  const currentAssetIdx = currIdx !== -1 ? currIdx : 0;

  const isLastPage = page === pages;
  const isFirstPage = page === 1;
  const isLastAssetOnPage = currentAssetIdx === assets.length - 1;
  const isFirstAssetOnPage = currentAssetIdx === 0;

  const nextPage = !isLastPage && isLastAssetOnPage ? page + 1 : page;
  const prevPage = !isFirstPage && isFirstAssetOnPage ? page - 1 : page;

  const prevIdx = currentAssetIdx > 0 ? currentAssetIdx - 1 : undefined;
  const nextIdx =
    currentAssetIdx < assets.length - 1 ? currentAssetIdx + 1 : undefined;

  const linkToPrev =
    prevIdx !== undefined
      ? `${assets[prevIdx]?.link}?assetPage=${page}`
      : `${albumLink}?assetPage=${prevPage}`;
  const linkToNext =
    nextIdx !== undefined
      ? `${assets[nextIdx]?.link}?assetPage=${page}`
      : `${albumLink}?assetPage=${nextPage}`;

  return { linkToPrev, linkToNext };
};

function AssetDetails(props: AssetDetailsProps) {
  const [searchParams] = useSearchParams();

  const page = getPage(searchParams, "assetPage");

  const { assets: albumAssets } = useAssets({
    collectionId: props.collectionId,
    albumId: props.albumId,
    searchQuery: props.searchQuery,
    offset: (page - 1) * albumViewPageSize,
    sortOrder: props.sortOrder,
  });

  const pages = Math.max(
    Math.ceil(albumAssets.assetsCount / albumViewPageSize),
    1,
  );

  const assets = albumAssets.assets ?? [];
  const albumLink = `/collection/${props.collectionId}/a/${props.albumId}`;

  const { linkToPrev, linkToNext } = getAssetNavigationLinks({
    assetId: props.id,
    page,
    pages,
    assets,
    albumLink,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        if (linkToNext) {
          navigate(linkToNext);
        }
      } else if (event.key === "ArrowLeft") {
        if (linkToPrev) {
          navigate(linkToPrev);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate, linkToNext, linkToPrev]);

  return (
    <div className={props.className} tw="flex flex-col sm:flex-row h-full">
      <div tw="grow h-full order-2 sm:order-1 relative justify-center overflow-auto">
        <ErrorBoundary
          fallback={
            <div tw="flex items-center justify-center h-screen">
              <div>Something went wrong loading the asset...</div>
            </div>
          }
        >
          {linkToPrev &&
            !props.shareId && ( // Todo: Disabled for share assets; not yet implemented
              <span>
                <Link to={linkToPrev} replace>
                  <i
                    className="mi-arrow-left"
                    tw="text-3xl absolute top-1/2 z-10 left-0 left-8"
                  ></i>
                </Link>
              </span>
            )}

          <LeftSide {...props} />
          {linkToNext &&
            !props.shareId && ( // Todo: Disabled for share assets; not yet implemented
              <Link to={linkToNext} replace>
                <i
                  className="mi-arrow-right"
                  tw="text-3xl absolute top-1/2 z-10 right-0 md:right-8"
                ></i>
              </Link>
            )}
        </ErrorBoundary>
      </div>

      <div tw="flex-none order-1 sm:order-2 w-full sm:w-1/2 xl:w-1/3 bg-bgbase px-3 py-4 space-y-2 sm:space-y-16 overflow-auto">
        <BackButton tw="mb-6" />
        <ErrorBoundary
          fallback={<div>Something went wrong loading the asset...</div>}
        >
          <RightSide {...props} />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export function AssetDetailsSkeleton() {
  return (
    <div tw="flex flex-col sm:flex-row h-full">
      <div tw="grow h-full order-2 sm:order-1 relative flex  justify-center items-center">
        <div tw="w-[90%] h-[60%] bg-grey-light animate-pulse" />
      </div>

      <div tw="flex-none order-1 sm:order-2 w-full sm:w-1/2 xl:w-1/3 bg-bgbase px-3 py-4 space-y-2 sm:space-y-16 overflow-auto">
        <div tw=" w-24 h-10 bg-grey-light animate-pulse rounded-full" />

        <div>
          <div tw=" w-[15%] h-6 bg-grey-light animate-pulse mt-8 sm:mt-24 mb-4" />
          <div tw=" w-[90%] h-10 bg-grey-light animate-pulse" />
        </div>

        <div>
          <div tw="w-[10%] h-6 bg-grey-light animate-pulse mb-4" />
          <div tw="flex space-x-2">
            <span tw=" w-[30%] h-10 bg-grey-light animate-pulse" />
            <span tw=" w-[30%] h-10 bg-grey-light animate-pulse" />
            <span tw=" w-[30%] h-10 bg-grey-light animate-pulse" />
          </div>
        </div>

        <div>
          <div tw="w-[45%] h-6 bg-grey-light animate-pulse mb-4" />
          <div tw="flex space-x-2">
            <span tw=" w-[30%] h-10 bg-grey-light animate-pulse" />
            <span tw=" w-[30%] h-10 bg-grey-light animate-pulse" />
            <span tw=" w-[30%] h-10 bg-grey-light animate-pulse" />
          </div>
        </div>

        <div tw="flex space-x-8">
          <span tw=" w-40 h-10 bg-grey-light animate-pulse rounded-lg" />
          <span tw=" w-32 h-10 bg-grey-light animate-pulse rounded-lg" />
        </div>
      </div>
    </div>
  );
}

function LeftSide(props: AssetDetailsProps) {
  const { asset } = useAsset({
    assetId: props.id,
    albumId: props.albumId,
    collectionId: props.collectionId,
  });
  let pdfUrl: string | boolean = false;

  if (asset.url && asset.url.match(/(\.pdf)$/i)) {
    pdfUrl = asset.url.replace("/i/", "/o/");
  }

  return (
    <>
      {typeof pdfUrl === "string" && (
        <div tw="items-center flex flex-col">
          <DocumentView
            fullUrl={pdfUrl}
            key={pdfUrl}
            // navigating the pdfurl pretty fast returns a null and breaks the page,
            // a key is added to reset State and recreate the component instance when pdfurl changes
          />
        </div>
      )}{" "}
      {!pdfUrl && (
        <img
          src={`/i/${asset.collection_id}/${asset.album_id}/${asset.id}/${asset.id}?w=1000&h=1000`}
          alt={asset.id}
          tw="max-h-[98%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </>
  );
}

function RightSide(props: AssetDetailsProps) {
  const { asset, deleteAsset, updateAssetMetadata } = useAsset({
    assetId: props.id,
    albumId: props.albumId,
    collectionId: props.collectionId,
  });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSkipDeleteDialog, setIsSkipDeleteDialog] = useState(false);

  const handleDelete = async () => {
    await deleteAsset.mutate();
    props.onClose();
  };

  const handleNewTag = asset.isOwner
    ? async (update: { key: string; value: string }) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          tags: [...asset.tags, { key: update.key, value: update.value }],
        };
        await updateAssetMetadata.mutate(dataPayload);
      }
    : undefined;

  const handleUpdateName = asset.isOwner
    ? async (title: string) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          name: title,
        };
        await updateAssetMetadata.mutate(dataPayload);
      }
    : undefined;

  const handleUpdateTag = asset.isOwner
    ? (
        tag: { key: string; value: string },
        update: { key: string; value: string },
      ) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          tags: asset.tags.map((t) =>
            t.key === tag.key && t.value === tag.value
              ? { key: update.key, value: update.value }
              : t,
          ),
        };
        updateAssetMetadata.mutate(dataPayload);
      }
    : undefined;

  const handleDeleteTag = asset.isOwner
    ? (tag?: { key: string; value: string }) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          tags: asset.tags.filter(
            (t) => t.key !== tag?.key || t.value !== tag?.value,
          ),
        };
        updateAssetMetadata.mutate(dataPayload);
      }
    : undefined;

  return (
    <>
      <AppTitle contentTitle={asset.name} />
      <div>
        <span tw="label mb-4">Name</span>
        <h2 tw="break-all">
          {" "}
          <EditableTitle title={asset.name} onUpdate={handleUpdateName} />
        </h2>
      </div>
      {props.showDeleteDialogOnNextDelete && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onAccept={() => {
            if (isSkipDeleteDialog) {
              props.setShowDeleteDialogOnNextDelete &&
                props.setShowDeleteDialogOnNextDelete(false);
            }
            handleDelete();
          }}
          onDecline={() => setIsDeleteDialogOpen(false)}
          body={
            <>
              Are you sure you want to delete this asset?
              <br /> This action cannot be undone.
              <div tw="flex items-center justify-center mt-5">
                <input
                  type="checkbox"
                  id="confirm-dialog-checkbox"
                  tw="mr-2"
                  checked={isSkipDeleteDialog}
                  onChange={() => setIsSkipDeleteDialog((prev) => !prev)}
                />
                <label htmlFor="confirm-dialog-checkbox">
                  Do not ask me again in this album
                </label>
              </div>
            </>
          }
          confirmText={"Delete asset"}
          declineText={"Cancel"}
          onClose={() => setIsDeleteDialogOpen(false)}
        />
      )}
      <div>
        <span tw="label mb-4">Tags</span>
        <ItemGroupContainer>
          {asset.labels.map((l) => (
            <TagItem
              key={"#" + l}
              tag={{ key: "#", value: l }}
              onUpdate={handleUpdateTag}
              onDelete={handleDeleteTag}
            />
          ))}
          {asset.tags.map((t) => (
            <TagItem
              key={t.key + t.value}
              tag={t}
              onUpdate={handleUpdateTag}
              onDelete={handleDeleteTag}
            />
          ))}
          <TagItem key="new-tag-input" onCreate={handleNewTag} />
          {asset.labels?.length || asset.tags?.length ? (
            <span tw="ml-0" />
          ) : null}
        </ItemGroupContainer>
        {asset.album_labels?.length || asset.album_tags?.length ? (
          <>
            <span tw="label font-normal mt-6 mb-4">
              Tags inherited from album
            </span>
            <ItemGroupContainer>
              {asset.album_labels?.map((l) => (
                <TagItem key={"#" + l} tag={{ key: "#", value: l }} />
              ))}
              {asset.album_tags?.map((t: any) => (
                <TagItem key={t[0] + t[1]} tag={{ key: t[0], value: t[1] }} />
              ))}
            </ItemGroupContainer>
          </>
        ) : null}
      </div>
      <div tw="flex space-x-8">
        <a href={asset.download_path} download>
          <NeuButton icon="mi-download">Download</NeuButton>
        </a>
        {asset.isOwner && !props.hideDeleteButton && (
          <NeuButton
            icon="mi-delete"
            tw="bg-[#ce4040]"
            onClick={() => {
              if (props.showDeleteDialogOnNextDelete) {
                setIsDeleteDialogOpen(true);
              } else {
                handleDelete();
              }
            }}
          >
            Delete
          </NeuButton>
        )}
      </div>
      {props.collectionId && props.albumId && asset.isOwner && (
        <div>
          <AssetSharedInList
            collectionId={props.collectionId}
            albumId={props.albumId}
            assetId={props.id}
          />
        </div>
      )}
    </>
  );
}

export default memo(AssetDetails);
