import { useState } from "react";
import "twin.macro";
import CollectionSelect from "./CollectionSelect";
import { NeuButton } from "../generic/Neu";
import { useShare } from "../../clients/apiHooks";
import { KeyOperatorValue, Share } from "../../clients/types";
import { CollectionKeyInput, CollectionValueInput } from "./KeyValueInput";

export interface AddNewQueryProps {
  shareId: string;
  queryId: string;
  onClose: () => void;
  share: Share;
}

export function EditQuery({
  shareId,
  queryId,
  onClose,
  share,
}: AddNewQueryProps) {
  const existingQuery = share.queries.find((q) => q.shareQueryId === queryId);
  const [collectionId, setCollectionId] = useState<string | null>(
    existingQuery?.collectionId ?? null,
  );
  const existingQueries = existingQuery?.query.map((kov) => ({ ...kov }));
  const [query, setQuery] = useState<KeyOperatorValue[]>(
    existingQueries ?? [{ key: "", operator: "=", value: "" }],
  );

  const { upsertShareQuery } = useShare(shareId);

  return (
    <div tw="flex flex-col items-start sm:pt-12 gap-6">
      <span tw="label mb-4 text-black">
        {existingQuery ? "Edit" : "Create"} query
      </span>
      <div tw="flex flex-col items-start gap-0">
        <span tw="mb-4 text-black">
          Select the collection that you want to share from:
        </span>
        <CollectionSelect value={collectionId} onChange={setCollectionId} />
      </div>
      {collectionId && (
        <div tw="flex flex-col items-start gap-2">
          <span tw="text-black">
            In this share group, add albums and assets
          </span>
          {query.map((q, i) => (
            <div tw="flex flex-row gap-2 items-center" key={i}>
              <span tw="text-black">{i === 0 ? "Where" : "And"}</span>
              <CollectionKeyInput
                tw="pl-2"
                collectionId={collectionId}
                placeholder="Key"
                value={q.key}
                onChange={(val) =>
                  setQuery((q) => {
                    q[i].key = val;
                    return [...q];
                  })
                }
              />
              <select
                value={q.operator}
                onChange={(ev) =>
                  setQuery((q) => {
                    q[i].operator = ev.target.value;
                    return [...q];
                  })
                }
              >
                <option value="=">=</option>
                <option value="!=">not =</option>
              </select>
              <CollectionValueInput
                tw="pl-2"
                collectionId={collectionId}
                keyValue={q.key}
                placeholder="Value"
                value={q.value}
                onChange={(val) =>
                  setQuery((q) => {
                    q[i].value = val;
                    return [...q];
                  })
                }
              />
              <NeuButton onClick={() => setQuery((q) => q.toSpliced(i, 1))}>
                <i className={"mi-delete"} />
              </NeuButton>
            </div>
          ))}
          <NeuButton
            tw="bg-transparent text-black"
            onClick={() =>
              setQuery((q) => [...q, { key: "", operator: "=", value: "" }])
            }
          >
            + Add Condition
          </NeuButton>
        </div>
      )}
      <div tw="flex flex-row justify-center rounded gap-2 pb-8">
        <NeuButton
          tw="p-2"
          disabled={
            !collectionId ||
            query.length === 0 ||
            query.some((q) => q.key === "" || q.value === "")
          }
          onClick={async () => {
            if (!collectionId) {
              return;
            }
            try {
              await upsertShareQuery.mutateAsync({
                collectionId: collectionId,
                shareId: shareId,
                shareQueryId: queryId === "new" ? undefined : queryId,
                query: query,
              });
              onClose();
            } catch (error) {
              console.error(error);
              // TODO show error
            }
          }}
        >
          Save Query
        </NeuButton>
        <NeuButton tw="p-2" onClick={onClose}>
          <i className={"mi-delete"} /> Cancel
        </NeuButton>
      </div>
    </div>
  );
}
