import React, { useState } from "react";
import "twin.macro";
import { useCollection } from "../../clients/apiHooks";
import ConfirmDialog from "./confirmDialog";
import { ShareQuery } from "../../clients/types";

export interface QueryRowProps {
  query: ShareQuery;
  deleteQuery: () => void;
  onEditQuery: null | ((queryId: string) => void);
}

export function QueryRow({ query, deleteQuery, onEditQuery }: QueryRowProps) {
  const { collection } = useCollection(query.collectionId);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <div tw="flex flex-row my-2">
      <div tw="flex flex-row gap-2">
        <div tw="flex gap-2" id={`${query.shareQueryId}_display`}>
          {collection?.name}:
          {query.query.map((kov, i) => (
            <span key={i}>
              {kov.key} {kov.operator} {kov.value}{" "}
            </span>
          ))}
        </div>
        <div tw="rounded flex justify-center cursor-pointer" id={`_edit`}>
          <button
            tw="px-2"
            disabled={!onEditQuery}
            onClick={() => {
              onEditQuery?.(query.shareQueryId);
            }}
          >
            <i className="mi-edit" />
          </button>
        </div>
        <div tw="border-none rounded flex justify-center cursor-pointer text-danger">
          <button
            tw="px-2"
            onClick={() => {
              setIsDeleteDialogOpen(true);
            }}
          >
            <i className={"mi-delete"} />
          </button>
        </div>
      </div>
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onAccept={deleteQuery}
        onDecline={() => setIsDeleteDialogOpen(false)}
        body={
          <>
            Are you sure you want to delete this query?
            <br /> This action cannot be undone.
          </>
        }
        confirmText={"Yes, delete query"}
        declineText={"No, take me back"}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </div>
  );
}
