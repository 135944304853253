import "twin.macro";

import React, { useCallback } from "react";
import ResourceInput from "../generic/ResourceInput";
import { useCreateAlbum, useCollection } from "../../clients/apiHooks";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../generic/Neu";

function NewAlbumView(): JSX.Element {
  const { collectionId } = useParams<{ collectionId: string }>();
  const { collection } = useCollection(collectionId!);
  const { createAlbum } = useCreateAlbum(collectionId!);

  const navigate = useNavigate();
  const handleSubmit = useCallback(
    async (val: string) => {
      const album = await createAlbum.mutateAsync(val);
      navigate(`/collection/${collectionId}/a/${album.id}`);
    },
    [createAlbum, navigate, collectionId],
  );

  return (
    <div tw="max-w-[1440px] p-1 flex flex-col gap-y-8 items-start">
      <BackButton tw="mb-8 sm:hidden" />
      <div tw="sm:mt-8">
        <span tw="label">New Album</span>
        <ResourceInput
          tw="text-4xl sm:text-6xl"
          placeholder="New album name"
          inputClass="text-4xl sm:text-6xl w-full"
          onSubmit={handleSubmit}
          cta="Create"
        />
      </div>
      <div>
        This will create a new album in the collection{" "}
        <span tw="font-bold">{collection?.name}</span>. You can add tags, labels
        and assets after its creation.
      </div>
    </div>
  );
}

export default NewAlbumView;
