export function getDomain(): string {
  const { protocol, hostname, port } = window.location;

  return `${protocol}//${hostname}${port ? ":" + port : ""}`;
}

export function triggerFileDownload(url: string) {
  const link = document.createElement("a");
  link.href = url;
  const filename = url.split("/").pop() || "";
  link.setAttribute("download", filename);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
