import "twin.macro";

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NeuButton, NeuInput } from "./generic/Neu";

export default function LoginView(): JSX.Element {
  const [value, setValue] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (evt: React.FormEvent) => {
      //TODO: Check if login is valid, if not dont navigate away
      evt.preventDefault();
      localStorage.setItem("token", value);
      navigate("/collection/default");
      // navigate(from, { replace: true }); TODO: Get the location from and redirect to last previous location for ease of use
    },
    [value, navigate],
  );

  return (
    <div tw="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <form onSubmit={handleSubmit} tw="flex space-x-8">
        <NeuInput
          name="token"
          type="password"
          value={value}
          placeholder="api-token"
          autoFocus
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setValue(evt.target.value)
          }
        />
        <input type="submit" name="submit" hidden />
        <NeuButton icon="mi-login" onClick={handleSubmit}>
          Login
        </NeuButton>
      </form>
    </div>
  );
}
