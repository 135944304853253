import "twin.macro";
import React, { ChangeEvent, useCallback, useRef } from "react";

import { useUploads } from "../../clients/apiHooks";
import { NeuButton } from "../generic/Neu";

export interface UploadsProps {
  albumId: string;
  collectionId: string;
}

export function Uploads({
  albumId,
  collectionId,
}: UploadsProps): JSX.Element | null {
  const { handleFileSelect } = useUploads(albumId, collectionId);

  const ref = useRef<HTMLInputElement>(null);

  const handleSelect = useCallback(() => {
    ref?.current?.click();
  }, [ref]);

  const handleAutoUpload = useCallback(
    (x: ChangeEvent<HTMLInputElement>) => {
      handleFileSelect(x);
    },
    [handleFileSelect],
  );

  return (
    <div>
      <form tw="inline-block">
        <label>
          <input
            ref={ref}
            hidden
            name="upload"
            type="file"
            multiple
            onChange={handleAutoUpload}
          />
          <NeuButton icon="mi-add" onClick={handleSelect}>
            Upload
          </NeuButton>
        </label>
      </form>
    </div>
  );
}

export default React.memo(Uploads);
