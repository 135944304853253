import "twin.macro";
import { Link, useMatch } from "react-router-dom";

export interface ListLinkProps {
  className?: string;
  name: string;
  href: string;
}

export function ListLink({
  className,
  href,
  name,
}: ListLinkProps): JSX.Element {
  const routeMatch = useMatch({
    path: href,
  });
  const nbsp = "\u00A0";
  return (
    <div className={className}>
      <Link to={href}>
        <div tw="overflow-hidden whitespace-nowrap text-ellipsis">
          {routeMatch && `>${nbsp}`}
          {name}
        </div>
      </Link>
    </div>
  );
}
