import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "mono-icons/iconfont/icons.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdPartyEmailPassword from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import * as Sentry from "@sentry/react";

import { GlobalStyles } from "twin.macro";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getDomain } from "./utils";
import { getEnvironment, isLocalhost, isProduction } from "./utils/env";

const domain = getDomain();

const SENTRY_DSN =
  "https://bdc81ffc1c1efd32484989ec495d58bc@o4508127388631040.ingest.de.sentry.io/4508127399575632";
if (!isLocalhost()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/([a-z]+\.)?exrepo.com\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: isProduction() ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: !isProduction(),
    environment: getEnvironment(),
  });
}

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: "Ex-Repo",
    apiDomain: domain,
    websiteDomain: domain,
    apiBasePath: "/auth",
    websiteBasePath: "/login",
  },
  recipeList: [
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        style: `
        [data-supertokens~=headerSubtitle] {
        display: none;
        }
        `,
        providers: [
          // Github.init(),
          // Google.init(),
          // Facebook.init(),
          // Apple.init(),
        ],
      },
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath;
          }
          if (context.isNewPrimaryUser) {
            // user signed up
          } else {
            // user signed in
          }
          return "/collection/";
        }
      },
    }),
    Session.init(),
  ],
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <SuperTokensWrapper>
      <App />
    </SuperTokensWrapper>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
