import "twin.macro";

import React from "react";
import { useOrganization } from "../../clients/apiHooks";
import { NeuButton } from "../generic/Neu";
import { Icon } from "../generic";

function OrganizationPage(): JSX.Element {
  const { organizationName, userList } = useOrganization();

  return (
    <div>
      <div tw="border-b border-grey items-center flex py-4 pl-4 justify-between pr-28">
        <span tw="text-3xl font-bold">{organizationName}</span>
        <NeuButton to={"/collection"} icon="mi-arrow-left">
          Back to collections
        </NeuButton>
      </div>

      <div tw="my-8 ml-12 mr-8 flex flex-col w-6/12">
        <div tw="mb-8 flex flex-row items-center justify-between">
          <div tw="text-3xl">Members</div>
          <div>{`${userList.length} members`}</div>
        </div>
        <div tw="divide-solid divide-y">
          {userList.map((user) => {
            return (
              <div tw="flex justify-between py-4">
                <div>
                  <Icon tw="mx-2" t="user" />
                  {user}
                </div>
                <div tw="flex flex-row gap-2">
                  <div>Admin</div>
                  <Icon t="user-remove"></Icon>{" "}
                  {/**Should we comment this out since it is unusable? */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OrganizationPage;
