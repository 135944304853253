import { Dispatch } from "react";
import { useWorkspace } from "../../clients/apiHooks";
import "twin.macro";

export interface CollectionSelectProps {
  value: string | null;
  onChange: Dispatch<string>;
}

export default function CollectionSelect({
  value,
  onChange,
}: CollectionSelectProps) {
  const { collections } = useWorkspace();
  return (
    <div tw="flex justify-center">
      <select
        name="collections"
        id="collection-select"
        onChange={(event) => onChange(event.target.value)}
        tw="p-2"
        value={value ?? "_prompt"}
      >
        {value === null && (
          <option key={"prompt"} value="_prompt">
            -Select collection-
          </option>
        )}
        {collections.map((collection) => {
          return (
            <option key={`${collection.id}_option`} value={collection.id}>
              {collection.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
