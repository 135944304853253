import tw from "twin.macro";
import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  getCollectionTagsKeys,
  getCollectionTagsValues,
} from "../../clients/apiClient";

type SuggestionType = "KEY" | "VALUE" | "OPERATOR";

interface PillProps {
  text: string;
  onRemove: (event: React.MouseEvent<HTMLElement>) => void;
  onEdit: (pillText: string, type: SuggestionType) => void;
}
const operators = ["!=", "="];

const Pill = ({ text, onRemove, onEdit }: PillProps) => {
  let operator = operators.find((op) => text.includes(op));
  let [key, value] = operator ? text.split(operator) : [text, ""];

  return (
    <span tw="inline-block border rounded border-[#a8a8a8] px-1 cursor-pointer hover:bg-grey">
      {operator ? (
        <>
          <span onClick={() => onEdit(text, "KEY")}>{key}</span>
          <span tw="pl-1" onClick={() => onEdit(text, "OPERATOR")}>
            {operator}
          </span>
          {value && (
            <span tw="pl-1" onClick={() => onEdit(text, "VALUE")}>
              {value}
            </span>
          )}
        </>
      ) : (
        <span onClick={() => onEdit(text, "KEY")}>{text}</span>
      )}
      <i
        className="mi-close"
        tw="pl-1 cursor-pointer hover:text-danger"
        onClick={onRemove}
      />
    </span>
  );
};

interface InputProps {
  currentQuery: string;
  pillQueries: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveTerm: (index: number) => void;
  inputEl: React.RefObject<HTMLInputElement>;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  onPillEdit: (pillText: string, type: SuggestionType) => void;
}

const Input = ({
  currentQuery,
  pillQueries,
  onChange,
  handleRemoveTerm,
  inputEl,
  onKeyPress,
  onKeyDown,
  onClearSearch,
  onPillEdit,
}: InputProps) => {
  return (
    <div tw="flex justify-between border bg-white rounded w-full gap-1 p-1">
      <div tw="flex flex-wrap gap-1">
        <i className="mi-search" tw="flex items-center" />
        {pillQueries.map((query, index) => (
          <Pill
            key={query}
            text={query}
            onRemove={() => handleRemoveTerm(index)}
            onEdit={onPillEdit}
          />
        ))}
        <input
          type="text"
          value={currentQuery}
          onChange={onChange}
          tw="bg-transparent border-none outline-none grow"
          size={currentQuery.length > 0 ? currentQuery.length : 15}
          ref={inputEl}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          placeholder={pillQueries.length === 0 ? "Search or filter..." : ""}
        />
      </div>
      {pillQueries.length > 0 && (
        <div>
          <span
            tw="inline-block rounded border border-[#a8a8a8] px-1 ml-auto bg-grey text-[#f0f0f0] cursor-pointer hover:(shadow-haptic-small bg-bgbase-dark text-[#0f0f0f])"
            onClick={onClearSearch}
          >
            <i className="mi-backspace" />
          </span>
        </div>
      )}
    </div>
  );
};

const getKey = (currentQuery: string): string | null => {
  if (currentQuery.includes("=") || currentQuery.includes("!=")) {
    return currentQuery.split(/=|!=/)[0];
  } else if (currentQuery.startsWith("#")) {
    return "#";
  } else {
    return "";
  }
};

const getValuePrefix = (currentQuery: string): string => {
  if (currentQuery.includes("=")) {
    return currentQuery.split("=")[1];
  } else if (currentQuery.includes("!=")) {
    return currentQuery.split("!=")[1];
  } else if (currentQuery.startsWith("#")) {
    return currentQuery.split("#")[1];
  } else {
    return "";
  }
};

const getQueryKeyWithOperator = (currentQuery: string): string => {
  if (currentQuery.includes("=")) {
    return currentQuery.substring(0, currentQuery.indexOf("=") + 1);
  } else if (currentQuery.includes("!=")) {
    return currentQuery.substring(0, currentQuery.indexOf("!=") + 2);
  } else {
    return "";
  }
};

interface SuggestionOption {
  text: string;
  label: string;
  type: SuggestionType | "SEARCH";
}

function getSuggestionOptions(
  key: string | null,
  collectionValues: string[],
  operator: string[],
  collectionKeys: string[],
): SuggestionOption[] {
  let suggestionOptions: SuggestionOption[];

  if (key) {
    suggestionOptions = collectionValues.map((text) => ({
      text,
      label: "Value",
      type: "VALUE",
    }));
  } else if (operator.length > 0) {
    suggestionOptions = operator.map((text) => ({
      text,
      label: text === "=" ? "is" : "is not",
      type: "OPERATOR",
    }));
  } else {
    suggestionOptions = collectionKeys.map((text) => ({
      text,
      label: "Key",
      type: "KEY",
    }));
  }
  return suggestionOptions;
}

interface SuggestionDropdownProps {
  suggestions: SuggestionOption[];
  selectedIndex: number | null;
  handleSuggestionClick: (suggestion: SuggestionOption) => void;
  setSelectedIndex: (index: number) => void;
}

const SuggestionDropdown = ({
  suggestions,
  selectedIndex,
  handleSuggestionClick,
  setSelectedIndex,
}: SuggestionDropdownProps) => {
  return (
    <div>
      {suggestions.map((suggestion, index) => (
        <p
          key={suggestion.text}
          css={[
            tw`flex justify-between cursor-pointer mt-0 p-2`,
            selectedIndex === index && tw`bg-grey`,
          ]}
          onClick={() => handleSuggestionClick(suggestion)}
          onMouseEnter={() => setSelectedIndex(index)}
        >
          {suggestion.text === "=" || suggestion.text === "!=" ? (
            <>
              <span>{suggestion.label}</span>
              <span>{suggestion.text}</span>
            </>
          ) : (
            <>
              <span tw="flex-grow">{suggestion.text}</span>
              <span tw="ml-2 max-w-[50%] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {suggestion.label}
              </span>
            </>
          )}
        </p>
      ))}
    </div>
  );
};

interface SearchInputProps {
  collectionId: string;
  setSearchQuery: (newValue: string) => void;
  searchQuery: string;
}

function SearchInput({
  collectionId,
  setSearchQuery,
  searchQuery,
}: SearchInputProps) {
  const currentSearch = searchQuery
    .split(" ")
    .filter((term) => term.trim() !== "");

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [pillQueries, setPillQueries] = useState<string[]>(currentSearch);
  const [operator, setOperator] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const inputEl = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const [collectionKeys, setCollectionKeys] = useState<{
    keys: string[];
  }>({
    keys: [],
  });

  const [collectionValues, setCollectionValues] = useState<{
    values: string[];
  }>({ values: [] });

  const key = getKey(currentQuery);
  const prefix = getValuePrefix(currentQuery);
  const queryKeyWithOperator = getQueryKeyWithOperator(currentQuery);

  function pillifyQuery(currentQuery: string) {
    if (currentQuery) {
      const uniquePillQueries = [...new Set([...pillQueries, currentQuery])];
      setPillQueries(uniquePillQueries);

      setCurrentQuery("");
      setOperator([]);
    }
  }

  useEffect(() => {
    if (!collectionId || !isFocused) return;
    const fetchCollectionData = async () => {
      if (key && prefix) {
        const collectionValues = await getCollectionTagsValues(
          collectionId,
          key,
          prefix,
        );
        setCollectionValues(collectionValues);
      } else {
        const collectionKeys = await getCollectionTagsKeys(
          collectionId,
          currentQuery ?? "",
        );
        setCollectionKeys(collectionKeys);
      }
    };

    fetchCollectionData();
  }, [collectionId, searchQuery, currentQuery, key, prefix, isFocused]);

  const suggestionOptions = useMemo(() => {
    const options = getSuggestionOptions(
      key,
      collectionValues.values,
      operator,
      collectionKeys.keys,
    );

    if (currentQuery) {
      options.push({
        text: `Search for`,
        label: `"${currentQuery}"`,
        type: "SEARCH",
      });
    }

    return options;
  }, [key, collectionKeys, collectionValues, operator, currentQuery]);

  const handleRemoveTerm = (index: number) => {
    const newSearchTerms = pillQueries.toSpliced(index, 1);
    setPillQueries(newSearchTerms);
    setSearchQuery(newSearchTerms.join(" "));
  };

  const previousQuery = searchQuery.split(" ").slice(0, -1).join(" ");
  const previousQueryWithSpace = previousQuery ? previousQuery + " " : "";

  const handleSuggestionClick = async (suggestion: SuggestionOption) => {
    setOperator([]);

    switch (suggestion.type) {
      case "SEARCH":
        pillifyQuery(currentQuery);
        break;
      case "VALUE":
        if (key && queryKeyWithOperator) {
          const newQuery = `${queryKeyWithOperator}${suggestion.text}`;
          setCurrentQuery(newQuery);
          setSearchQuery(`${previousQueryWithSpace}${newQuery}`);
          pillifyQuery(newQuery);
        }
        break;
      case "OPERATOR":
        if (operator.length > 0) {
          const newQuery = `${currentQuery}${suggestion.text}`;
          setCurrentQuery(newQuery);
          setSearchQuery(`${previousQueryWithSpace}${newQuery}`);
          const collectionValues = await getCollectionTagsValues(
            collectionId,
            key || currentQuery,
            "",
          );
          setCollectionValues(collectionValues);
        }
        break;
      case "KEY":
        setCurrentQuery(suggestion.text);
        setSearchQuery(`${previousQueryWithSpace}${suggestion.text}`);
        setOperator(operators);
        break;
    }

    inputEl.current?.focus();
  };

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (selectedIndex !== null && suggestionOptions.length > 0) {
        const selectedSuggestion = suggestionOptions[selectedIndex];
        handleSuggestionClick(selectedSuggestion);
      } else if (currentQuery) {
        pillifyQuery(currentQuery);
      }

      setSelectedIndex(null);
    }
  };

  const handleOnkeyMove = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!suggestionOptions || suggestionOptions.length === 0) {
      return;
    }

    if (event.key === "ArrowDown") {
      setSelectedIndex((prevIndex) =>
        prevIndex === null || prevIndex >= suggestionOptions.length - 1
          ? 0
          : prevIndex + 1,
      );
      return;
    }

    if (event.key === "ArrowUp") {
      setSelectedIndex((prevIndex) =>
        prevIndex === null || prevIndex <= 0
          ? suggestionOptions.length - 1
          : prevIndex - 1,
      );
      return;
    }

    if (
      event.key === "Backspace" &&
      currentQuery === "" &&
      pillQueries.length > 0
    ) {
      event.preventDefault();
      const lastPill = pillQueries[pillQueries.length - 1];
      setCurrentQuery(lastPill);

      setPillQueries(pillQueries.slice(0, -1));
    }
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;

    setCurrentQuery(value);
    setSearchQuery([...pillQueries, value].join(" "));

    if (value.includes("!=") || value.includes("=")) {
      const query = getKey(value);

      const collectionValues = await getCollectionTagsValues(
        collectionId,
        query ?? "",
        "",
      );
      setCollectionValues(collectionValues);
    } else {
      setOperator([]);
    }
  };

  const clearSearch = () => {
    setPillQueries([]);
    setSearchQuery("");
    setCurrentQuery("");
  };

  const handleEditPill = (pillText: string, type: SuggestionType) => {
    if (currentQuery.trim()) {
      pillifyQuery(currentQuery);
    }

    setCurrentQuery(pillText);

    setPillQueries((prevPills) =>
      prevPills.filter((pill) => pill !== pillText),
    );

    let cursorPosition = 0;
    let operator = operators.find((op) => pillText.includes(op));
    let [key, value] = operator ? pillText.split(operator) : [pillText, ""];

    if (type === "KEY") {
      cursorPosition = key.length;
    } else if (type === "OPERATOR") {
      cursorPosition = key.length + operator!.length;
    } else if (type === "VALUE") {
      cursorPosition = key.length + operator!.length + value.length;
    }

    inputEl.current?.focus();
    setTimeout(() => {
      inputEl.current?.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  return (
    <div
      ref={divRef}
      tw="flex flex-col w-96 relative inline-block shrink cursor-text h-full"
      onBlur={(ev) => {
        if (!divRef.current?.contains(ev.relatedTarget) || !ev.relatedTarget) {
          pillifyQuery(currentQuery);
          setIsFocused(false);
        }
      }}
      onFocus={async () => {
        inputEl.current?.focus();
        setIsFocused(true);
      }}
      tabIndex={0}
    >
      <Input
        currentQuery={currentQuery}
        pillQueries={pillQueries}
        onChange={handleInputChange}
        handleRemoveTerm={handleRemoveTerm}
        inputEl={inputEl}
        onKeyPress={handleKeyPress}
        onKeyDown={(event) => {
          handleOnkeyMove(event);
        }}
        onClearSearch={clearSearch}
        onPillEdit={handleEditPill}
      />
      {isFocused && (
        <div
          tw="absolute top-full right-0 left-0 w-full border rounded z-10 overflow-auto shadow-card bg-grey-light"
          tabIndex={0}
        >
          <SuggestionDropdown
            suggestions={suggestionOptions}
            selectedIndex={selectedIndex}
            handleSuggestionClick={handleSuggestionClick}
            setSelectedIndex={setSelectedIndex}
          />
        </div>
      )}
    </div>
  );
}

export default SearchInput;
