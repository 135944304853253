import "twin.macro";
import { ErrorBoundary } from "@sentry/react";
import { AlbumCol, MarginWrapper, MenuCol } from "../generic/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../generic/Neu";
import AlbumView from "../album/AlbumView";
import { useShare } from "../../clients/apiHooks";
import { ShareContents } from "./ShareContents";
import { Modal } from "../generic";
import AssetDetails, { AssetDetailsSkeleton } from "../album/AssetDetails";
import { ContentWrapper } from "../collection/CollectionView";
import { EditableTitle } from "../album/EditableTitle";
import { createUpdateTitle } from "./ShareView";
import { SuspenseWrapper } from "../generic/SuspenseWrapper";
import { AppTitle } from "../Title";
import { useState } from "react";
import { SortOrder } from "../../clients/types";

export function ShareAlbumView() {
  const { shareId, assetId, albumId, collectionId } = useParams<{
    shareId: string;
    assetId: string;
    albumId: string;
    collectionId: string;
  }>();
  const { share, patchShare } = useShare(shareId);

  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const handleCloseAssetDetails = () => {
    navigate(-1);
  };

  const updateName = createUpdateTitle(share, patchShare.mutateAsync);

  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the share...</div>}
    >
      <AppTitle contentTitle={share?.name} />
      <ContentWrapper tw=" px-3">
        <MenuCol active={true}>
          <span tw="py-4 label">Share</span>
          <div tw="text-3xl sm:text-7xl w-full">
            <EditableTitle
              tw="sm:mb-4"
              title={share?.name || ""}
              onUpdate={updateName}
            />
          </div>
          {share && <ShareContents share={share} />}
        </MenuCol>
        <AlbumCol active={true}>
          <MarginWrapper isCenter={true}>
            <BackButton tw="sm:hidden" />
            <AlbumView
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              shareId={shareId}
            />
          </MarginWrapper>
        </AlbumCol>
      </ContentWrapper>
      {assetId ? (
        <Modal>
          <SuspenseWrapper fallback={<AssetDetailsSkeleton />}>
            <AssetDetails
              tw="w-full h-full"
              id={assetId}
              albumId={albumId!}
              collectionId={collectionId!}
              shareId={shareId}
              onSelect={handleCloseAssetDetails}
              onClose={handleCloseAssetDetails}
              searchQuery={searchQuery}
              sortOrder={sortOrder}
              hideDeleteButton
            />
          </SuspenseWrapper>
        </Modal>
      ) : null}
    </ErrorBoundary>
  );
}
